$(document).ready(function () {
    $('input[name=risikoområde]').change(function () {
      var name = $(this).val();

      $('.package').hide();
      if (name !== 'on') {
        $('.' + name).show();
      } else {
        $('.package').show();
      }
    });
  });
  