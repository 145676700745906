window.addEventListener('DOMContentLoaded', (event) => { // activate the script after everything has loaded
    // actions
    function getAndSendQueryWord(event) {
        let input = $('#SiteSearchInput');
        console.log(input.data('sok'));
        console.log(input.val());
        window.location.href = input.data('sok') + '?search=' + input.val()
    };

    // listeners
    try {
        document.getElementById('SiteSearchInput').addEventListener('keyup', function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
                getAndSendQueryWord();
            }
        });
        
        $('#searchButton').click(function(ev) {
            ev.preventDefault();
            getAndSendQueryWord();
        });
        
        document.getElementById('SiteSearchInputMobile').addEventListener('keyup', function (event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                getAndSendQueryWord();
            }
        });
        
        $('#searchButtonMobile').click(function(ev) {
            ev.preventDefault();
            getAndSendQueryWord();
        });
    } catch (err) { console.log('search.js: ' + err); }
});
