$(document).ready(function () {
	$(window).scroll(bindScroll);
});

function bindScroll() {
    var loadArticleUrl = $("#article-load-more").attr("data-article-load-url");
    if (($(window).scrollTop() + $(window).height() >= $('.landing-cards').offset().top + $('.landing-cards').height()) && (loadArticleUrl)) {
        $(window).unbind('scroll');
        loadMore(loadArticleUrl);
    }
}

function loadMore(loadArticleUrl) {
    $.ajax({
        url: loadArticleUrl,
        success: function (data) {
            var newPageElements = $(data).find("#article-append-load .col-md-4");
            $("#article-append-load").append(newPageElements);
            
            var nextPageUrl = $(data).find("#article-load-more").attr("data-article-load-url");
            $("#article-load-more").attr("data-article-load-url", nextPageUrl);
            $(window).bind('scroll', bindScroll);
        },
        cache: false
    });
}