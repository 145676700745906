$(document).ready(function () {
    try {
        var text = document.getElementById('interventionContent');
        if (text) {
            var headings = text.querySelectorAll('h1, h2, h3, h4, h5, h6');
    
            headings.forEach(function (e) {
                var innerHtml = e.innerHTML;
                var id = innerHtml.toLowerCase().replace(/ /g, '-');
                e.setAttribute('id', id);
                
                var a = document.createElement('a');
                a.setAttribute('href', '#' + id);
                a.innerHTML = innerHtml;
                
                var span = document.createElement('span');
                span.setAttribute('class', 'article__nav__item');
                span.appendChild(a);
                
                var sectionBar = document.getElementById('interventionSectionBarNav');
                sectionBar.appendChild(span);
            });
        
            if (headings.length === 0) {
                var sectionBar = document.getElementById('interventionSectionBar');
                sectionBar.remove();
            }
        }
    } catch (err) { console.log('heading.js: ' + err); }
    
    $('.article__nav__item').click(function () {
       $('span').removeClass('article__nav__item--active');
       $(this).addClass('article__nav__item--active'); 
    });
});
